import il8n from '@/assets/language/index.js';
const { t } = il8n.global;
import { parmsArray } from '@/config/data'

export const role = [{
    type: 'select',
    name: t("inface.text3"),
    prop: 'tenantId',
    isPostApi: 'shopList',
    isPlatform: 1,//平台显示，商户不显示
    props:{
        value:'id',
        label:'name'
    },
},{
    type: 'input',
    name: t("adminRole.name2"),
    prop: 'roleName'
},{
    type: 'input',
    name: t("adminRole.name3"),
    prop: 'roleCode'
},{
    type: 'select',
    name: t("adminRole.name5"),
    prop: 'dsType', 
    isParms: parmsArray[0]
}]