<template>
    <div class="init">
        <el-form :model="fromValue" label-width="auto"  ref='form_' class="fromClass"
            :rules='rules' label-position="top">
            <platformCheck @setTenantId='setTenantId_' :tenantId_1='fromValue.tenantId'/>
            <el-form-item :label='$t("adminRole.name2")' prop="roleName">
                <el-input v-model="fromValue.roleName" :placeholder="$t('inputTit.shuru')"/>
            </el-form-item>
            <el-form-item :label='$t("adminRole.name3")' prop="roleCode">
                <el-input v-model="fromValue.roleCode" :placeholder="$t('inputTit.shuru')"/>
            </el-form-item>
            <el-form-item :label='$t("adminRole.name4")' prop="roleDesc">
                <el-input v-model="fromValue.roleDesc" :placeholder="$t('inputTit.shuru')" type="textarea"/>
            </el-form-item>
            <el-form-item :label="$t('adminRole.name5')" prop="dsType">
                <el-select v-model="fromValue.dsType" :placeholder="$t('inputTit.xuanze')">
                    <el-option :label="item.label" :value="item.value" v-for='item in dsTypeArray' :key = 'item.value'  />
                </el-select>
            </el-form-item>
        </el-form>
    </div>
</template>
<script setup>
    import { ref,defineExpose,reactive,defineEmits,getCurrentInstance } from 'vue'
    // import addrView from './addr.vue'
    import Api_ from '@/api/index'
    import { ElNotification } from 'element-plus'
    import addRules from './addRules'
    import platformCheck from '@/components/platformCheck.vue'  
    const { $t } = getCurrentInstance().proxy;
    import { getDataAboutTextArray } from '@/utils/dataBind/parmsView'
    
    const fromValue = reactive({ 
        roleId: '',roleName: '',roleCode:'',
        roleDesc: '',dsType:'',tenantId:''
    })

    const setTenantId_ = (e)=>{
        fromValue.tenantId = e
    }
    const form_ = ref(null)
    const rules = ref(null); rules.value = addRules.mainRules
    const style_ = ref(0)
    const dsTypeArray = ref([]);dsTypeArray.value = getDataAboutTextArray(0)

    const emit = defineEmits(['successFun'])
    
    
    const completeFun = ()=>{
        if (!form_.value) return
        form_.value.validate((e) => {
            if(e){
                if(style_.value === 0){
                    let obj_ = JSON.parse(JSON.stringify(fromValue))
                    delete obj_.roleId
                    Api_.addRole(obj_).then(res=>{
                        if(res.code === 0){
                            emit('successFun')
                            ElNotification({
                                message: $t('alert.succAdd'),
                                type: 'success',
                            })
                        }else{
                            ElNotification({
                                message: res.msg,
                                type: 'error',
                            })
                        }
                    })
                }else{
                    Api_.updateRole(fromValue).then(res=>{
                        if(res.code === 0){
                            emit('successFun')
                            ElNotification({
                                message: $t('alert.succUpdate'),
                                type: 'success',
                            })
                        }else{
                            ElNotification({
                                message: res.msg,
                                type: 'error',
                            })
                        }
                    })
                }
            }
        })
    }
    const init_ = (e,obj)=>{//初始化数据
        style_.value = e;
        if(obj){
            for(let index in fromValue){
                fromValue[index] = obj[index]
            }
            fromValue.dsType = fromValue.dsType.toString()
        }else{
            for(let index in fromValue){
                if(index !== 'tenantId'){
                    fromValue[index] = ''
                }
            }
        }
    }
    defineExpose({init_,completeFun})
</script>