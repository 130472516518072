// import api_ from '@/api/index'
import il8n from '@/assets/language/index.js';
const { t } = il8n.global;

// var validateRoleCode = (rule, value, callback) => {
//     api_.getRoleList({roleCode:value}).then(response => {
//         const result = response.data.records
//         if (window.boxType === 'edit') {
//             if (result && result.length !== 0 && result[0].roleId !== window.boxID) {
//                 callback(new Error(t("adminRole.rules1")))
//             } else {
//                 callback()
//             }
//         }else{
//             if (result && result.length !== 0) {
//                 callback(new Error(t("adminRole.rules1")))
//             } else {
//                 callback()
//             }
//         }
//     })
// }
// var validateRoleName = (rule, value, callback) => {
//     api_.getRoleList({roleName:value}).then(response => {
//         const result = response.data.records
//         if (window.boxType === 'edit') {
//             if (result && result.length !== 0 && result[0].roleId !== window.boxID) {
//                 callback(new Error(t("adminRole.rules2")))
//             } else {
//                 callback()
//             }
//         }else{
//             if (result && result.length !== 0) {
//                 callback(new Error(t("adminRole.rules2")))
//             } else {
//                 callback()
//             }
//         }
//     })
// }
export const role1 = {
    border: true,
        index: true,
        indexLabel: t("adminRole.name1"),
        indexWidth: 80,
        height: 'auto',
        stripe: true,
        menuAlign: 'center',
        searchMenuSpan: 6,
        align: 'center',
        refreshBtn: false,
        columnBtn: false,
        addBtn: false,
        editBtn: false,
        delBtn: false,
        menu:false,
        viewBtn: false,
        column: [{
            label: t("adminRole.name2"),
            prop: 'roleName',
        },{
            label: t("currencySet.name1"),
            prop: 'tenantName',
            // 'search': true,
        }, {
            label: t("adminRole.name3"),
            prop: 'roleCode',
        }, {
            width: 150,
            label: t("adminRole.name4"),
            prop: 'roleDesc',
            overHidden: true,
            type: 'textarea',
            row: true,
            minRows: 2,
            rules: [
                { max: 128, message: t("adminRole.rules4"), trigger: 'blur' }
            ]
        }, {
            width: 150,
            label: t("adminRole.name5"),
            prop: 'dsType',
        }, 
        // {
        //     label: '',
        //     prop: 'dsScope',
        //     formslot: true,
        //     showClomnu: false,
        //     hide: true,
        //     type: 'tree',
        //     dicData: [],
        // }, 
        {
            label: t("adminRole.name6"),
            prop: 'createTime',
        },{
            width: 240,
            label: t("button.operation"),
            prop: 'caozuo',
            placeholder: t("inputTit.shuru"),
            fixed:'right',
          }]
}