<template>
    <template v-if='isPlatform_'>
        <el-form-item :label="$t('currencySet.name1')" >
            <el-select v-model="tenantId_" :placeholder="$t('inputTit.xuanze')" :teleported='false'>
                <el-option :label="item.name" :value="item.id" v-for='item in tenantArray' :key = 'item.id'/>
            </el-select>
        </el-form-item>
    </template>
</template>
<script setup>
    import { ref,watchEffect,defineEmits,defineProps } from 'vue'
    import { userStore } from '@/store/user';///userStore().getIsPlatform
    import apiList from '@/const/apiList'

    const emit = defineEmits(['setTenantId'])
    const isPlatform_ = ref(null);isPlatform_.value = userStore().getIsPlatform;
    const tenantArray = ref([])
    const props = defineProps({
        tenantId_1: String
    })
    const tenantId_ = ref('');
    // tenantId_.value = props.tenantId_1?props.tenantId_1:userStore().getUserInfo[0].tenantId;

    apiList.getTenantList((data)=>{ tenantArray.value = data })
    emit('setTenantId',tenantId_.value)
    watchEffect(()=>{
        emit('setTenantId',tenantId_.value)
        console.log(props.tenantId_1,3415)
    })

</script>