<template>
    <div>
        <fromSearch :object_='object_' @searchFun='getList' @changeParm='changeParm'/>
        <avue-crud :option="tableOption" :data="page.list" :page="page" 
            :table-loading="listLoading" @size-change="sizeChange"
            @current-change="currentChange" >
            <template #menu-left>
                <el-button v-if="roleManager_btn_add" class="filter-item"
                    type="primary"  @click="openView(0)">{{$t('button.add')}} </el-button>
                <el-button v-if="sys_role_export" class="filter-item" plain
                    type="primary"  @click="exportExcel" >{{$t('button.imports')}} </el-button>
            </template>
            <template #createTime="scope">
                {{turnTimeFun(scope.row.createTime)}}
            </template>
            <template #dsType="scope">
                {{getDataAboutText(0,scope.row.dsType)}}
            </template>
            <template #caozuo="scope">
            <el-button v-if="roleManager_btn_edit" text type="primary"
                size="small"    @click="openView(1,scope.row)">{{$t('button.update')}} </el-button>
            <el-button v-if="roleManager_btn_del" text type="primary" size="small"
                  @click="rowDel(scope.row)">{{$t('button.del')}} </el-button>
            <el-button v-if="roleManager_btn_perm" text type="primary" size="small"  
                @click="handlePermission(scope.row,scope.index)">{{$t('role.text1')}} </el-button>
            </template>
        </avue-crud>
    <el-dialog v-model="dialogPermissionVisible" :close-on-click-modal="false" :title="$t('role.text2')" lock-scroll destroy-on-close >
      <div class="dialog-main-tree">
        <el-tree ref="menuTree" :data="treeData" :default-checked-keys="checkedKeys"
          :props="defaultProps" :filter-node-method="filterNode" check-strictly
          class="filter-tree" node-key="id" highlight-current
          show-checkbox  default-expand-all/>
      </div>
      <template #footer>
        <div class="dialog-footer">
            <el-button type="default" size="small" @click="dialogPermissionVisible = false">{{$t('button.close')}} </el-button>
            <el-button type="primary" size="small" @click="updatePermession(roleId)">{{$t('button.newer')}} </el-button>
        </div>
      </template>
    </el-dialog>
    <el-dialog v-model="dialogFormVisible" :title='isStyleText[isStyle]' width="600">
        <bindView ref='bindView_' @successFun='successFun'/>
        <template #footer>
            <el-button    @click="dialogFormVisible = false">{{$t('button.closeQ')}} </el-button>
            <el-button type="primary"   @click="completeFun">{{$t('button.tijiao')}}</el-button>
        </template>
    </el-dialog>
  </div>
</template>

<script setup>
import { ref,nextTick,getCurrentInstance } from 'vue'
import Api_ from '@/api/index'
import { ElNotification } from 'element-plus'
import { downBlobFile,resolveAllEunuchNodeId } from '@/utils/util'
import { turnTimeFun } from '@/utils/util.js'
import bindView from './bind.vue'
import mixins from '@/views/mixins/page'
import fromSearch from '@/components/fromSearch.vue'
import { role1 } from "@/const/crud/role";
import { role } from '@/const/from/platform/role'
import { getDataAboutText } from '@/utils/dataBind/parmsView'
import { ElLoading } from 'element-plus'

const tableOption = ref(role1)
const object_ = ref(role);

const { $t } = getCurrentInstance().proxy;
let pageObj = mixins(['getRoleList',['delRole','roleId']])
const {page,dialogFormVisible,listLoading,sizeChange,currentChange,getList,rowDel,successFun,changeParm} = pageObj
const loadingList = ref({})

const isStyle = ref(0)
const isStyleText = [$t("button.add"),$t("button.update")]

const dialogPermissionVisible = ref(false)
const treeData = ref([])
const checkedKeys = ref([])
const defaultProps = {
    label: 'name',
    value: 'id'
}
const roleId = ref(null)
const menuTree = ref(null)

 //用户权限
const roleManager_btn_add = ref(true)
const sys_role_export = ref(true)
const roleManager_btn_edit = ref(true)
const roleManager_btn_del = ref(true)
const roleManager_btn_perm = ref(true)

const bindView_ = ref(null)
const openView = (num,parm)=>{
    isStyle.value = num
    dialogFormVisible.value = true
    nextTick(()=>{
        bindView_.value.init_(num,parm)
    })
}
const filterNode = (value, data)=>{
    if (!value) return true
    return data.label.indexOf(value) !== -1
}
const updatePermession = ()=>{
    let menuTree_ = menuTree.value.getCheckedKeys().join(',').concat(',').concat(menuTree.value.getHalfCheckedKeys().join(','))
    Api_.permissionUpd(roleId.value, menuTree_).then(() => {
        dialogPermissionVisible.value = false
        ElNotification({
            message: $t('alert.succUpdate'),
            type: 'success',
        })
      })
}
const handlePermission = (row) => {
    roleId.value = row.roleId;checkedKeys.value=[]
    loadingList.value = ElLoading.service({
        text: 'Loading'
    })
    Api_.fetchRoleTreeNew(row).then(response => {
        checkedKeys.value = response.data
        return Api_.fetchMenuTree()
    }).then(response => {
        treeData.value = response.data
        // 解析出所有的太监节点
        checkedKeys.value = resolveAllEunuchNodeId(treeData.value, checkedKeys.value, [])
        for(let i = 0; i< checkedKeys.value.length;++ i){
            let _i = checkedKeys.value[i]
            if(_i.length > 1){
                checkedKeys.value[i] = [_i[0]]
            }
        }
        dialogPermissionVisible.value = true
        loadingList.value.close()
    })
}
const exportExcel = ()=>{//{{$t('button.imports')}}
    downBlobFile('/admin/role/export',"role.xlsx")
}
const completeFun = ()=>{
    bindView_.value.completeFun()
}
getList(1)
</script>
